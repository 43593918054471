import React, {useRef, useState} from "react";
import moment from "moment";
import {
  Box,
  Button,
  Checkbox, Container,
  Grid,
  InputAdornment,
  MenuItem, Paper,
  Select,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {camelCase, isEmpty, range, toString} from "lodash";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'
import {HelpOutlineOutlined} from "@mui/icons-material";
import {useStyles} from "./multiple-generate-label.styles";
import {colors} from "assets";
import clsx from "clsx";

import {countries} from "utils"
import {specieses, sampleTypes, grades, printLabelSize } from 'constants/form'
import {ModalWrapper, LightTooltip} from "components";

import {MultipleGenerateLabelProps} from "./multiple-generate-lable.props";
import {PrintLabel} from "../generate-label/print-label/print-label";


export const MultipleGenerateLabel: React.FC<MultipleGenerateLabelProps> = observer((props: MultipleGenerateLabelProps) => {
  const classes = useStyles()
  const { open, onClose, index, onSubmit, samplesToBePrintLabel} = props
  const { t } = useTranslation()

  const [typeValue, setTypeValue] = React.useState<any[]>([])
  const [paperSize, setPaperSize] = useState("letter")
  const refInputData = useRef('')
  const [disableCheckboxes, setDisableCheckboxes] = React.useState<any[]>([])
  const [dataTmp, setDataTmp] = useState([{attribute: "", status: false, value: "", values: typeValue}]);
  const [data, setData] = React.useState<any[]>([])
  const [openPrintLabel, setOpenPrintLabel] = useState(false)

  const dataSamples = samplesToBePrintLabel[index]['sample']

  const defaultData = [
    {
      attribute: "qrcode",
      status: true,
      value: samplesToBePrintLabel["qrcode"],
      values: typeValue
    },
    {
      attribute: "sampleId",
      status: false,
      value: dataSamples["sampleUniqueNumber"],
      values:typeValue
    },
    {
      attribute: "description",
      status: [printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["description"],
      value: dataSamples["description"],
      values:typeValue
    },
    {
      attribute: "sampleType",
      status: [printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!samplesToBePrintLabel[index]["sampleType"],
      value: samplesToBePrintLabel[index]["sampleType"],
      values:typeValue
    },
    {
      attribute: "species",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["species"],
      value: dataSamples["species"],
      values:typeValue
    },
    {
      attribute: "country",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["countryCode"],
      value: dataSamples["countryCode"],
      values:typeValue
    },
    {
      attribute: "sampleName",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!samplesToBePrintLabel[index]["sampleName"],
      value: samplesToBePrintLabel[index]["sampleName"],
      values:typeValue
    },
    {
      attribute: "cropYear",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["cropYear"],
      value: dataSamples["cropYear"],
      values:typeValue
    },
    {
      attribute: "supplier",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["supplierName"],
      value: dataSamples["supplierName"],
      values:typeValue
    },
    {
      attribute: "purchaseContractReference",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["purchaseContractReference"],
      value: dataSamples["purchaseContractReference"],
      values:typeValue
    },
    {
      attribute: "client",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["customer"],
      value: dataSamples["customer"],
      values:typeValue
    },
    {
      attribute: "salesContractReference",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["salesContractReference"],
      value: dataSamples["salesContractReference"],
      values:typeValue
    },
    {
      attribute: "referenceNumber",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["referenceNumber"],
      value: dataSamples["referenceNumber"],
      values:typeValue
    },
    {
      attribute: "purchaseGrade",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["purchaseGrade"],
      value: dataSamples["purchaseGrade"],
      values:typeValue
    },
    {
      attribute: "grade",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["grade"],
      value: dataSamples["grade"],
      values:typeValue
    },
    {
      attribute: "coffeeProcessing",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["processName"],
      value: dataSamples["processName"],
      values:typeValue
    },
    {
      attribute: "numberOfBags",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["numberOfBag"],
      value: dataSamples["numberOfBag"],
      values:typeValue
    },
    {
      attribute: "bagWeight",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["bagWeight"],
      value: dataSamples["bagWeight"],
      values:typeValue
    },
    {
      attribute: "shipmentMonth",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["shipmentDate"],
      value: dataSamples["shipmentDate"] ? moment(dataSamples["shipmentDate"]).format('L') : '',
      values:typeValue
    },
    {
      attribute: "varietals",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["varietalsTags"],
      value: dataSamples["varietalsTags"],
      values:typeValue
    },
    {
      attribute: "moisture",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["moisture"],
      value: dataSamples["moisture"],
      values:typeValue
    },
    {
      attribute: "waterActivity",
      status:[printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["waterActivity"],
      value: dataSamples["waterActivity"],
      values:typeValue
    },
    {
      attribute: "cuppingDate",
      status: [printLabelSize[1].key, printLabelSize[4].key].includes(paperSize) ? false : !!dataSamples["cuppingDate"],
      value: dataSamples["cuppingDate"],
      values:typeValue
    },
    {
      attribute: "paperSize",
      status: true,
      value: paperSize === printLabelSize[1].key ? "dk" : "letter",
      values:typeValue
    },
  ]

  const getSymbol = (index) => {
    switch (index) {
      case "moisture":
        return "%"
      case "bagWeight":
        return "kg"
      default:
        return ""

    }

  }

  const renderCheckbox = (rowIndex ,attribute , info, indexValue, checkAttribute) => {

    return(
      <Checkbox
        disabled={disableCheckboxes.includes(checkAttribute)}
        checked={info}
        onChange={(event) => {
          refInputData.current = 'value' + attribute
          handlerUpdateCheckbox(rowIndex, indexValue, event.target.checked)
        }}
      />
    )
  }

  const renderEditDataValues = (rowIndex ,attribute , info, indexValue) => {
    const key = 'value' + attribute
    switch (attribute) {
      case "qrcode":
        return(
          <Box></Box>
        )
      case "sampleType":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdateLabel(rowIndex, indexValue, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {sampleTypes.map(item =>
              <MenuItem key={item} value={item}>{t(`options.sampleType.${camelCase(item)}`)}</MenuItem>
            )}
          </Select>
        )
      case "country":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdateLabel(rowIndex, indexValue, e.target.value)
            }}
          >
            <MenuItem value='' sx={{height: 34}} />
            {countries.map(item =>
              <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
            )}
          </Select>
        )
      case "grade":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdateLabel(rowIndex, indexValue, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {grades.map(item =>
              <MenuItem key={item} value={item}>{t(`options.grades.${item}`)}</MenuItem>
            )}
          </Select>
        )
      case "cropYear":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdateLabel(rowIndex, indexValue, e.target.value)
            }}
          >
            <MenuItem value='' sx={{height: 34}} />
            {range((new Date()).getFullYear(), 2010).map(item =>
              <MenuItem key={item} value={toString(item)}>{item}</MenuItem>
            )}
          </Select>
        )
      case "species":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdateLabel(rowIndex, indexValue, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {specieses.map(item =>
              <MenuItem key={item} value={item}>{t(`options.species.${item}`)}</MenuItem>
            )}
          </Select>
        )
      case "numberOfBags":
      case "bagWeight":
      case "moisture":
      case "waterActivity":
        return(
          <CurrencyTextField
            fullWidth
            size='small'
            type='text'
            inputMode='numeric'
            variant='outlined'
            textAlign='left'
            currencySymbol=''
            autoFocus={key === refInputData.current}
            value={info}
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            decimalPlaces={0}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdateLabel(rowIndex, indexValue, e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getSymbol(attribute)}
                </InputAdornment>)
            }}
          />
        )
      default:
        return(
          <TextField
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            value={info}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdateLabel(rowIndex, indexValue, e.target.value)
            }}
          />
        )
    }

  }

  const handlerPaperSize = (e) => {
    dataTmp[23]["value"] = e.target.value
    setPaperSize(e.target.value)
  }

  const updateDisableCheckboxes = (paperSize, limit) => {
    const activeCount = dataTmp.filter(x => x.status === true).length;
    disableCheckboxes.length = 0;

    if (activeCount >= limit) {
      dataTmp.filter(x => x.status === false).forEach(d => {
        disableCheckboxes.push(d.attribute);
      });
    }
  };

  const handlerUpdateCheckbox = (rowIndex, indexValue, value) => {
    setDataTmp(prevData => {
      const newData = [...prevData]
      newData[rowIndex]['status'] = value
      return newData
    })

    // limit checkbox
    switch(paperSize){
      case printLabelSize[1].key:
        updateDisableCheckboxes(paperSize, 10)
        break
      case printLabelSize[3].key:
        updateDisableCheckboxes(paperSize, 12)
        break
      case printLabelSize[4].key:
        updateDisableCheckboxes(paperSize, 8)
        break
      default:
        updateDisableCheckboxes(paperSize, 24)
        break
    }
  }

  const handlerUpdateLabel= (rowIndex, indexValue, value) => {
    setDataTmp(prevData => {
      const newData = [...prevData]
      newData[rowIndex]['values'][indexValue] = value
      return newData
    })
  }

  const setKey = (key) => {
    switch (key) {
      case "sampleUniqueNumber":
        return "sampleId"
      case "supplierName":
        return "supplier"
      case "processName":
        return "coffeeProcessing"
      case "numberOfBag":
        return "numberOfBags"
      case "customer":
        return "client"
      case "shipmentDate":
        return "shipmentMonth"
      case "varietalsTags":
        return "varietals"
      case "name":
        return "sampleName"
      case "createdAt":
        return "cuppingDate"
      case "countryCode":
        return "country"
      default:
        return key

    }
  }

  const setDefaultDataTmp = () => {
    defaultData.map((d, i) => {
      let tmpData: any[] = []
      samplesToBePrintLabel.filter( data => data.approvalStatus !== "declined").map( samples => {
        Object.keys(samples.sample).map((key) => {
          if(key === "country") return
          let keys = setKey(key)

          if(d.attribute !== keys) return
          if(keys === "cuppingDate") {
            tmpData.push([])
            return
          }
          tmpData.push(samples.sample[key])

        })
      })

      defaultData[i]["values"]= tmpData
    })
    setDataTmp(defaultData)
  }

  const updateData = () => {
    let tmpData: any[] = []
    for (var i = 0; i < samplesToBePrintLabel.filter( data => data.approvalStatus !== "declined").length; i++) {
      const attributes = dataTmp.map( d => {
        let dataValue = ''

        if(d.attribute === "paperSize"){
            dataValue = paperSize
        } else {
          if(isEmpty(d.values[i])){
            dataValue = ""
          } else {
            dataValue = d.values[i]
          }

        }
        return {attribute: d.attribute, status: d.status, value: dataValue}
      })
      tmpData.push(attributes)
    }
    setData(tmpData)

    onSubmit(tmpData)
    setOpenPrintLabel(true)
  }

  const renderContent = (
    <Box>
      <Grid container spacing={2} sx={{ mb: 3 , mt: 1}}>
        <Grid item xs={6}>
          <Typography sx={{mb: 1}}>{t('printLabel.title')}</Typography>
          <Typography sx={{ fontWeight: 200 }}>{t('printLabel.sharedInformationDescription')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'row',
              mb: 1
            }}
          >
            <Typography sx={{mr: 1}}>{t('printLabel.selectLabelSize')} </Typography>
            <LightTooltip
              arrow
              title={t('printLabel.selectLabelSizeTooltip')}
            >
              <HelpOutlineOutlined color='primary' />
            </LightTooltip>
          </Box>
          <Select
            fullWidth
            size='small'
            defaultValue=""
            value={paperSize}
            onChange={(e) => handlerPaperSize(e)}
          >
            {printLabelSize.map(item =>
              <MenuItem key={item.key} value={item.key}>{t(`options.${item.label}`)}</MenuItem>
            )}
          </Select>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        className={classes.table}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} ></TableCell>
              <TableCell className={classes.tableHeader} ></TableCell>
              <TableCell align="center" colSpan={samplesToBePrintLabel.length}>{t('printLabel.sample')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTmp.filter( x => (x.attribute === "sampleName" || x.attribute === "sampleId")).map((data, rowIndex) => {
              return(
                <TableRow key={rowIndex}>
                  <TableCell className={clsx(classes.tableRow,'row')}>
                  </TableCell>
                  <TableCell align="center" className={clsx(classes.tableRow,'row')}>
                    {rowIndex === 0 && t('printLabel.information')}
                  </TableCell>
                  {data.values.map((d,i) => {
                    return(
                      <TableCell className={clsx(classes.tableRow,'row')} sx={{backgroundColor: colors.primary.o25}}>
                        {d}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}

            {dataTmp.filter( x => x.attribute !== "paperSize").map((data, rowIndex) => {
              return(
                <TableRow key={rowIndex}>
                  <TableCell className={classes.tableRow}>
                    {renderCheckbox(rowIndex, "status", data.status, 0, data.attribute)}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    {t(`printLabel.${data.attribute}`)}
                  </TableCell>
                  {data.values.map((d,i) => {
                    return(
                      <TableCell className={classes.tableRow}>
                        {renderEditDataValues(rowIndex, data.attribute, d, i)}
                      </TableCell>
                    )
                  })}

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        spacing={2}
        sx={{mb: 1, mt: 4, justifyContent: "flex-end", alignItems: "center"}}>
        <Button
          variant='contained'
          onClick={() => {
            updateData()
          }}
        >
          {t('shipment.button.submit')}
        </Button>
        <Button variant='outlined' onClick={() =>{onClose()}}>
          {t('shipment.button.cancel')}
        </Button>
      </Stack>
    </Box>
  )

  React.useEffect(() => {
    if(isEmpty(JSON.parse(samplesToBePrintLabel[index].label))){
      setPaperSize('letter')
      return
    }

    let dataPrint = JSON.parse(samplesToBePrintLabel[index].label)
    let findIndex = dataPrint.findIndex( d => d.attribute === "paperSize")
    setPaperSize(dataPrint[findIndex]["value"])
  }, [index])

  React.useEffect(() => {
    setDefaultDataTmp()
  }, [dataSamples.uniqueToken])

  React.useEffect(() => {
    setDefaultDataTmp()
  }, [samplesToBePrintLabel.length])

  React.useEffect(() => {
    disableCheckboxes.length = 0
    setDefaultDataTmp()
  }, [paperSize])

  return (
    <ModalWrapper
	    maxWidth={paperSize !== "letter" && openPrintLabel ? 'sm' : 'xl'}
      open={open}
      {...openPrintLabel && {
        onClose: () => {
          setOpenPrintLabel(false)
          onClose()
        }
      }}
    >

      {openPrintLabel ? <PrintLabel dataLabels={data}/> :
        renderContent
      }
    </ModalWrapper>
  )
})
