import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {cloneDeep, isEqual} from "lodash";
import moment from "moment";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Autocomplete, Box, Button, Stack, TextField, Typography,
  Accordion, AccordionSummary, AccordionDetails, Divider, SvgIcon, Checkbox
} from "@mui/material";
import {Check, Clear, Edit, ExpandMore, HelpOutline, Visibility} from "@mui/icons-material";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'
import ReactHtmlParser from 'react-html-parser'
import {isMobile} from "react-device-detect"
import { v4 as uuidv4 } from 'uuid'

import {useStores} from "models";
import {globalAction, optionsTranslation} from "utils";
import {BasicTable, ModalConfirm, ModalMessage} from "components";
import {emptyCupping, IconShippingFast, IconTotalScore} from "assets/images";
import {ApproveReject, ApproveRejectButton} from "modules";
import { colors } from "assets";

import {SampleInformationModal} from "../index";
import {useStyles} from "pages/sample-details/sample-details.styles";

const columnHelper = createColumnHelper<any>()

export const CuppingResults = observer((props: any) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const { isReport, selectedCuppingResult = new Set(), onChangeSelectedCuppingResult, loadSample } = props
  const {
    companyStore: { isSucafinaFeature },
    cuppingSessionStore: { getCuppingSession },
    masterSampleStore: { getChildSample, saveAggregate, saveAggregateWithSync,
      masterSampleDetail: { cuppingResultAggregate: { totalScore, descriptors, internalNotes, syncBy, syncAt, setValue: setCuppingResultValue }}
    },
    cuppingResultStore: { cuppingResult, page, totalPage, setValue, getCuppingResults },
    notificationStore
  } = useStores()

  const syncDataStore = {
    totalScore,
    descriptors: descriptors?.slice() || [],
    internalNotes,
  }

  const [openConfirmApprove, setOpenConfirmApprove] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openQcReason, setOpenQcReason] = useState(false)
  const [transactionId, setTransactionId] = useState('')
  const [transactionItemId, setTransactionItemId] = useState('')
  const [openSampleInformationModal, setOpenSampleInformationModal] = useState(false)
  const [openSyncModal, setOpenSyncModal] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [editTotalScore, setEditTotalScore] = useState(false)
  const [saveOnly, setSaveOnly] = useState(false)
  const data = cloneDeep(cuppingResult)

  const onGetSample = async (masterSampleUniqueToken: string, sampleUniqueToken: string) => {
    try {
      notificationStore.setLoading(true)
      await getChildSample(masterSampleUniqueToken, sampleUniqueToken)
      setOpenSampleInformationModal(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const onSyncSample = () => {
    setOpenSyncModal(false)

    globalAction(notificationStore, {
      action: async () => {
        await saveAggregateWithSync([{
          ...syncDataStore,
          masterSampleToken: params.id,
          sampleScoresIds: []
        }])
      },
      afterAction: () => {
        setOpenSuccess(true)
      }
    })
  }

  const onSaveSample = () => {
    setOpenSyncModal(false)

    globalAction(notificationStore, {
      action: async () => {
        await saveAggregate([{
          ...syncDataStore,
          masterSampleToken: params.id,
          sampleScoresIds: []
        }])
      },
      afterAction: () => {
        setOpenSuccess(true)
        setSaveOnly(true)
      }
    })
  }

  const renderApprovalButton = (info) => {
    const { sampleTransactionUniqueToken, sampleTransactionItemUniqueToken } = info.row.original

    if (!sampleTransactionUniqueToken || !sampleTransactionItemUniqueToken) return null

    const setUniqueToken = () => {
      setTransactionId(sampleTransactionUniqueToken)
      setTransactionItemId(sampleTransactionItemUniqueToken)
    }

    return (
      <Stack direction="row" justifyContent="center" spacing={1}>
        <ApproveRejectButton
          row={info.row.original}
          approveChildren={
            <>
              <Check />
              {isMobile && t('shipment.button.approve')}
            </>
          }
          onApprove={(hasApproval) => {
            setUniqueToken()
            if (hasApproval) setOpenQcReason(true)
            setOpenConfirmApprove(true)
          }}
          rejectChildren={
            <>
              <Clear />
              {isMobile && t('shipment.button.reject')}
            </>
          }
          onReject={(hasApproval) => {
            setUniqueToken()
            if (hasApproval) setOpenQcReason(true)
            setOpenRejectModal(true)
          }}
          buttonProps={{fullWidth: isMobile}}
        />
      </Stack>
    )
  }

  const renderSync = (
    <Box mb={2}>
      <Typography fontSize="20px">{t('sampleAggregate.title')}</Typography>
      <Box display="flex" justifyContent="space-between" my={2}>
        <Stack direction="row" spacing={2} width="60%">
          <CurrencyTextField
            key={`totalScore-${uuidv4}${editTotalScore ? '' : totalScore}`}
            autoFocus={editTotalScore}
            fullWidth
            size='small'
            type='text'
            inputMode='numeric'
            variant='outlined'
            textAlign='left'
            currencySymbol=''
            outputFormat="number"
            decimalCharacter="."
            digitGroupSeparator=","
            minimumValue="0"
            maximumValue="100"
            decimalPlaces={2}
            placeholder={t('sampleAggregate.score')}
            style={{width: '100px'}}
            onFocus={() => setEditTotalScore(true)}
            value={totalScore}
            onChange={(event, value) => {
              setCuppingResultValue('totalScore', value)
            }}
            InputProps={{ sx: { height: '100%' }}}
          />
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            sx={{width: '100%'}}
            value={descriptors?.length > 0 ? descriptors : []}
            onChange={(_, value) => {
              setCuppingResultValue('descriptors', value ?? [])
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('sampleAggregate.descriptorPlaceholder')}
                variant="outlined"
                size="small"
              />
            )}
            onKeyDown={(event) => {
              if (event.key === ',') {
                event.key = 'Enter'
              }
            }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ px: 4, ml: 2 }}
            onClick={() => onSaveSample()}
          >
            {t('sampleAggregate.save')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ px: 4, ml: 2 }}
            onClick={() => setOpenSyncModal(true)}
          >
            {t('sampleAggregate.sync')}
          </Button>
        </Stack>
      </Box>

      <TextField
        placeholder={t('sampleAggregate.internalNotes')}
        variant="outlined"
        size="small"
        sx={{width: '60%'}}
        value={internalNotes}
        onChange={(event) => setCuppingResultValue('internalNotes', event.target.value)}
      />

      {syncBy && syncAt && (
        <Typography variant="body2" my={1}>
          {ReactHtmlParser(t('sampleAggregate.syncInformation', {
            name: syncBy, date: moment.utc(syncAt).local().format('L - LT')
          }))}
        </Typography>
      )}
    </Box>
  )

  const renderButtons = (row) => {
    const { sampleUniqueToken, masterSampleUniqueToken, uniqueToken, type } = row
    const info = { row: { original: row } }

    return (
      <Stack direction={isMobile ? "column" : "row"} justifyContent="center" spacing={1}>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Button
            fullWidth={isMobile}
            variant="contained"
            onClick={() => onGetSample(masterSampleUniqueToken, sampleUniqueToken)}
            startIcon={<Edit />}
            className={classes.button}
          >
            {isMobile && t('common.edit')}
          </Button>
          {uniqueToken && (
            <Button
              fullWidth={isMobile}
              variant={isMobile ? "outlined" : "contained"}
              onClick={() => onReviewCuppingSession(uniqueToken, sampleUniqueToken)}
              startIcon={<Visibility />}
              className={classes.button}
            >
              {isMobile && t('sample.button.preview')}
            </Button>
          )}
        </Stack>

        {type === "SampleTransactionItemScore" && renderApprovalButton(info)}
      </Stack>
    )
  }

  const columns = [
    ...(isReport ? [
      columnHelper.accessor('id', {
        id: 'id',
        header: () => <Box />,
        cell: info => {
          const value = info.renderValue()
          const isSelected = selectedCuppingResult.has(value.toString());

          return (
            <Box textAlign="left">
              <Checkbox
                checked={isSelected}
                onChange={() => onChangeSelectedCuppingResult(value)}
              />
            </Box>
          )
        }
      }),
    ] : []),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <Box textAlign="left">{t('sample.detail.cuppingSessionId')}</Box>,
      cell: info =>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Typography>{info.renderValue()}</Typography>
          {info.row.original.type === 'SampleTransactionItemScore' && (
            <IconShippingFast width={32} height={32} fill={colors.primary.main} />
          )}
        </Stack>,
    }),
    columnHelper.accessor('cupperName', {
      id: 'cupperName',
      header: () => <Box textAlign="left">{t('sample.detail.cupperName')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cuppingDate', {
      id: 'cuppingDate',
      header: () => <Box textAlign="left">{t('sample.detail.cuppingDate')}</Box>,
      cell: info => moment(info.renderValue()).format('L'),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('sample.detail.sampleType')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue()),
    }),
    columnHelper.accessor('salesContract', {
      id: 'salesContract',
      header: () => <Box textAlign="left">{t('sample.detail.salesContract')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cuppingProtocol', {
      id: 'cuppingProtocol',
      header: () => <Box textAlign="left">{t('sample.detail.cuppingProtocol')}</Box>,
      cell: info => optionsTranslation('cuppingProtocol', info.renderValue()),
    }),
    columnHelper.accessor('totalDefect', {
      id: 'totalDefect',
      header: () => t('sample.detail.totalDefect'),
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('totalScore', {
      id: 'totalScore',
      header: () => t('sample.detail.totalScore'),
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
    ...(isReport ? [] : [
      columnHelper.accessor('action', {
        id: 'action',
        header: () => t('sample.detail.action'),
        cell: info => renderButtons(info.row.original),
      })
    ])
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const renderMobile = (row) => {
    return (
      <Accordion key={row.id} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box>
            <Typography variant='h6'>{row.id}</Typography>
            <Stack
              direction="row"
              spacing={0.5}
              divider={<Divider orientation="vertical" flexItem sx={{borderColor: "#999FA4"}} />}
            >
              <Box display="flex" alignItems="center">
                <SvgIcon component={IconTotalScore} inheritViewBox color="primary" sx={{mr: 0.5}} />
                <Typography variant="h6">{row.totalScore}</Typography>
              </Box>
              <Typography variant="body2" color="#999FA4">{optionsTranslation('cuppingProtocol', row.cuppingProtocol)}</Typography>
            </Stack>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="content">
          <table cellPadding={2}>
            <tr>
              <td>{t('sample.detail.cupperName')}</td>
              <td>{row.cupperName}</td>
            </tr>
            <tr>
              <td>{t('sample.detail.cuppingDate')}</td>
              <td>{moment(row.cuppingDate).format('L')}</td>
            </tr>
            <tr>
              <td>{t('sample.detail.sampleType')}</td>
              <td>{optionsTranslation('sampleType', row.sampleType)}</td>
            </tr>
            <tr>
              <td>{t('sample.detail.salesContract')}</td>
              <td>{row.salesContract}</td>
            </tr>
            <tr>
              <td>{t('sample.detail.cuppingProtocol')}</td>
              <td>{optionsTranslation('cuppingProtocol', row.cuppingProtocol)}</td>
            </tr>
            <tr>
              <td>{t('sample.detail.totalDefect')}</td>
              <td>{row.totalDefect}</td>
            </tr>
          </table>

          {renderButtons(row)}
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return data.map((item) => renderMobile(item))
    }

    return (
      <BasicTable
        table={table}
        page={page}
        totalPage={totalPage}
        onChangePage={onChangePage}
        isFetching={isFetching}
        {...(isReport && {paginationStyle: {mb: 0}})}
      />
    )
  }

  const onChangePage = (_, p) => {
    setValue('page', p)
  }

  const onReviewCuppingSession = (cuppingSessionId: string, sampleUniqueToken: string) => {
    if (!cuppingSessionId) return

    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await getCuppingSession(cuppingSessionId)

        navigate(`/cupping-session-review?sample-id=${sampleUniqueToken}`)
      }
    })
  }

  const onGetCuppingResult = () => {
    (async () => {
      try {
        setIsFetching(true)
        await getCuppingResults(params.id)
      } finally {
        setIsFetching(false)
      }
    })()
  }

  useEffect(() => {
    onGetCuppingResult()
  }, [page])

  useEffect(() => {
    setValue('page', 1)
  }, [])

  if (isReport) return renderContent()

  return (
    <Box px={2} py={2.5}>
      {isSucafinaFeature && renderSync}
      {cuppingResult.length > 0 ? renderContent()
        : (
        <Box py={4} textAlign="center">
          <img src={emptyCupping} alt="Empty cupping" />
          <Typography mt={2} variant="h5">{t('sample.detail.emptyCuppingResult')}</Typography>
        </Box>
      )}

      <SampleInformationModal
        open={openSampleInformationModal}
        onClose={() => setOpenSampleInformationModal(false)}
      />

      <ApproveReject
        id={transactionId}
        transactionItemId={transactionItemId}
        setTransactionItemId={setTransactionItemId}
        openQcReason={openQcReason}
        setOpenQcReason={setOpenQcReason}
        openConfirmApprove={openConfirmApprove}
        setOpenConfirmApprove={setOpenConfirmApprove}
        openRejectModal={openRejectModal}
        setOpenRejectModal={setOpenRejectModal}
        onSuccess={onGetCuppingResult}
      />

      <ModalConfirm
        rtl
        open={openSyncModal}
        title={t('sampleAggregate.syncModalTitle')}
        description={t('sampleAggregate.syncModalDescription')}
        onConfirm={onSyncSample}
        onCancel={() => setOpenSyncModal(false)}
        confirm={t('sampleAggregate.sync')}
      />

      <ModalMessage
        open={openSuccess}
        onSubmit={() => {
          setOpenSuccess(false)
          setSaveOnly(false)
          loadSample()
        }}
        description={saveOnly ? "" : t('sampleAggregate.successSync')}
        buttonText={t('common.backToPage')}
      />
    </Box>
  )
})
