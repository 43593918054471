import React, {useEffect, useRef, useState} from "react"
import {camelCase, cloneDeep, has, isEmpty, uniq} from "lodash";
import { useTranslation } from "react-i18next"
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {
	Box,
	Typography,
	Stack,
	FormControlLabel,
	Checkbox,
	Button, IconButton, Select, MenuItem, TextField, InputAdornment
} from "@mui/material"
import {CloseOutlined, Edit as EditIcon, Edit, ExpandLess, ExpandMore, Feed} from "@mui/icons-material"
import {
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import {useStores} from "models";
import {colors} from "assets";
import { findRoute } from 'constants/routes'
import {BasicTable, FormInput, HeaderTitleWide, LightTooltip, ModalConfirm, ModalMessage} from "components"
import {countryName, globalAction, optionsTranslation, str2bool} from "utils"
import {fulfillmentTypes, fulfillmentStatus as constantsFulfillmentStatus} from "constants/form"
import {GenerateLabel, MultipleGenerateLabel} from "pages/modal";
import {SampleShipmentFulfillmentStatus, SampleShipmentApproval} from "constants/constant";
import { SampleInformationModal, ShipmentInformation, ShipmentInfoModal } from "modules"

import { useStyles } from "./sample-order-form.styles"
import { ShipmentTable, DeclineModal } from "./components"

const columnHelper = createColumnHelper<any>()

export const SampleOrderForm = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()

  const {
    companyStore: { name: companyName },
    sampleOrderStore: {
      samplesToBeShipment, shipmentInformation, groupSamplesToBeShipment, isFormComplete, sampleShipmentCounter, isFormCompleteWithoutEmail,
      setSamplesToBeShipment, setThirdPartyEmails, saveSampleShipment, updateSampleOrder, saveSubOrderSampleShipment, setSubOrderStatus, setSubOrderNote, setSubOrderIsCreatedShipment,
      setSamplesToBeShipmentSample, getSampleOrder, setSamplesToBeShipmentGenerateLabel, setSamplesToBeShipmentMultiGenerateLabel, setValue: setSampleOrderValue
    },
    notificationStore
  } = useStores()
  const unfulfilled = shipmentInformation?.fulfillmentStatus === SampleShipmentFulfillmentStatus.UNFULFILLED
  const fulfilled = shipmentInformation?.fulfillmentStatus === SampleShipmentFulfillmentStatus.FULFILLED

  const homeLink = findRoute('sampleOrders')
  const [rowSelection, setRowSelection] = useState({})
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [openSuccessEditedSample, setOpenSuccessEditedSample] = useState(false)
  const [openConfirmShipment, setOpenConfirmShipment] = useState(false)
  const [openSuccessCreatedShipment, setOpenSuccessCreatedShipment] = useState(false)
  const refInput = useRef('')
  const [openGenerateLabel, setOpenGenerateLabel] = useState(false)
  const [openSampleModal, setOpenSampleModal] = useState(false)
  const [indexSamples, setIndexSamples] = useState(-1)
  const [openShipmentInfoModal, setOpenShipmentInfoModal] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [descriptionSuccess, setDescriptionSuccess] = useState('')
  const [declineModal, setDeclineModal] = useState(false)
  const [transactionItemId, setTransactionItemId] = useState('')
  const [changed, setChanged] = useState(false)
  const [openMultipleGenerateLabel, setMultipleOpenGenerateLabel] = useState(false)
  const [dataMultipleDecline, setDataMultipleDecline] = useState<any[]>([])
  const [isMultipleDecline, setIsMultipleDecline] = useState(false)
  const [openSuccessCreatedShipmentSubOrder, setOpenSuccessCreatedShipmentSubOrder] = useState(false)

  const resetRefInput = () => {
	  refInput.current = ''
  }

  const onSetDataValue = (rowIndex, key, value) => {
  
    if(key === 'sampleFulfillmentType' && groupSamplesToBeShipment[value]?.[0]?.isCreatedShipment) return

    if(key === 'sampleFulfillmentType' &&  groupSamplesToBeShipment[data[rowIndex].sampleLocation]?.[0]?.isCreatedShipment) return

	  setData(old => {
		  const newData = [...old]
		  let currentData = newData[rowIndex]

		  if (has(currentData, key)) currentData[key] = value
		  if (has(currentData, `sample.${key}`)) currentData.sample[key] = value
		  if (key === 'sampleFulfillmentType' && value === fulfillmentTypes[0]) {
				currentData.sampleLocation = companyName
		  }

			newData[rowIndex] = currentData
		  return newData
	  })
  }

  const renderButtons = () => {
    if (!unfulfilled) return null

    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => setOpenShipmentInfoModal(true)}
      >
        {t('common.edit')}
      </Button>
    )
  }

	const renderTextField = (info, field) => {
		const key = field + info.row.index

		return (
			<TextField
				fullWidth
				size='small'
				autoFocus={key === refInput.current}
				value={info.renderValue()}
				onChange={(e) => {
					refInput.current = key
					onSetDataValue(info.row.index, field, e.target.value)
				}}
				sx={{minWidth: 150}}
			/>
		)
	}

  const renderEditSampleWeight = (info, disabled) => {
    const key = 'sampleWeight' + info.row.index

    if(isEdit && !disabled){
      return (
        <CurrencyTextField
          size='small'
          type='text'
          inputMode='numeric'
          variant='outlined'
          textAlign='left'
          currencySymbol=''
          value={info.renderValue()}
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          decimalPlaces={0}
          autoFocus={key === refInput.current}
          onChange={(event, value)=> {
            refInput.current = key
            onSetDataValue(info.row.index,'sampleWeight', value)
          }}
          InputProps={{
            sx: {minWidth: 125},
            endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
          }}
        />
      )
    }

    return (
      <Box textAlign="center">
        {info.renderValue() ? `${info.renderValue()} gr` : '-'}
      </Box>
    )
  }

  const renderSubItem = (label, value) => {
    const val = value ? value : '-'
    return (
      <Box>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body2">{val}</Typography>
      </Box>
    )
  }

  const renderSubComponent = ({ row }) => {
    const { isRoasted, sample, approvalStatus, reason, deliveryInformation } = row.original
    const declined = approvalStatus === SampleShipmentApproval.DECLINED

    const items = [
      { label: t('shipment.table.producer'), value: sample.producerName },
      { label: t('shipment.table.salesContract'), value: sample.salesContractReference },
      { label: t('shipment.table.processing'), value: sample.processName },
    ]

    return (
      <Box className={classes.collapseWrapper}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          {items.map(item => renderSubItem(item.label, item.value))}
        </Stack>

	      {isEdit ?
		      <Box mt={2}>
			      <FormInput
				      label={t('shipment.table.deliveryNotes')}
				      textFieldProps={{
								value: deliveryInformation,
					      onChange: (e) => onSetDataValue(row.index, 'deliveryInformation', e.target.value),
					      multiline: true,
					      rows: 2,
				      }}
				      formControlProps={{
					      sx: {
						      backgroundColor: 'transparent',
						      '& .MuiInputBase-formControl': {
							      backgroundColor: 'white'
						      }
					      }
				      }}
			      />
		      </Box> :
		      <Box mt={2}>
			      <Typography variant="subtitle1">{t('shipment.table.deliveryNotes')}</Typography>
			      <Typography variant="body2">{deliveryInformation ?? '-'}</Typography>
          </Box>
        }

        {declined && (
          <Box mt={2}>
            <Typography variant="subtitle1">{t('order.reasonWasDeclined')}</Typography>
            <Typography variant="body2">{reason ?? '-'}</Typography>
          </Box>
        )}
      </Box>
    )
  }

  const columns = [
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: ({ table }) => {
        const disableHeaderCheckbox = table.getRowModel().rows.some(row => row.original.isCreatedShipment);

        return(
        <Box textAlign="left">
          <FormControlLabel
            label={t('shipment.table.type')}
            control={
              <Checkbox
	              disabled={isEdit || disableHeaderCheckbox}
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            }
          />
        </Box>
        )
      }
        ,
      cell: info => {
        const createSubOrderShipment = info.row.original.isCreatedShipment

        return(
          <Box textAlign="left">
		      <FormControlLabel
			      label={optionsTranslation('sampleType', info.renderValue(), true)}
			      control={
				      <Checkbox
					      disabled={isEdit || createSubOrderShipment}
					      checked={info.row.getIsSelected()}
					      onChange={info.row.getToggleSelectedHandler()}
				      />
			      }
		      />
	      </Box>
        )
      }
    }),
	  columnHelper.accessor('sample.purchaseContractReference', {
		  id: 'sample.purchaseContractReference',
		  header: () => <Box textAlign="left">{t('shipment.table.purchaseContract')}</Box>,
		  cell: info => info.renderValue(),
	  }),
    columnHelper.accessor('sample.purchaseGrade', {
      id: 'sample.purchaseGrade',
      header: () => <Box textAlign="left">{t('shipment.table.purchaseGrade')}</Box>,
      cell: info => info.renderValue()
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
      cell: info => {
        const declined = info.row.original.approvalStatus === SampleShipmentApproval.DECLINED
        const createSubOrderShipment = info.row.original.isCreatedShipment
        const disabled = declined || createSubOrderShipment
        if(isEdit && !disabled){
          return renderTextField(info, 'sampleLocation')
        }
        return info.renderValue()
      }
    }),
	  columnHelper.accessor('sample.countryCode', {
		  id: 'sample.countryCode',
		  header: () => <Box textAlign="left">{t('shipment.table.country')}</Box>,
		  cell: info => countryName(info.renderValue())
	  }),
	  columnHelper.accessor('sample.warehouseReference', {
		  id: 'sample.warehouseReference',
		  header: () => <Box textAlign="left">{t('shipment.table.warehouse')}</Box>,
		  cell: info => info.renderValue()
	  }),
    columnHelper.accessor('sampleFulfillmentType', {
      id: 'sampleFulfillmentType',
      header: () => <Box textAlign="left">{t('shipment.table.fulfillmentType')}</Box>,
      cell: info => {
        const declined = info.row.original.approvalStatus === SampleShipmentApproval.DECLINED
        const createSubOrderShipment = info.row.original.isCreatedShipment
        const disabled = declined || createSubOrderShipment

        if(isEdit && !disabled){
          return(
            <Select
            size='small'
            value={info.renderValue()}
            onChange={(e) => {
              resetRefInput()
              onSetDataValue(info.row.index, 'sampleFulfillmentType', e.target.value)
            }}
          >
            {fulfillmentTypes.map(item =>
              <MenuItem key={item} value={item}>{optionsTranslation('fulfillmentType', item)}</MenuItem>
            )}
          </Select>
          )
        }

        return optionsTranslation('fulfillmentType', info.renderValue())

      }
    }),
    columnHelper.accessor('isRoasted', {
      id: 'isRoasted',
      header: () => t('shipment.table.roasted'),
      cell: info => {
        const declined = info.row.original.approvalStatus === SampleShipmentApproval.DECLINED
        const createSubOrderShipment = info.row.original.isCreatedShipment
        const disabled = declined || createSubOrderShipment

        if(isEdit && !disabled){
          return(
            <Select
            size='small'
            value={info.renderValue()}
            onChange={(e) => {
              resetRefInput()
              onSetDataValue(info.row.index, 'isRoasted', str2bool(e.target.value))
            }}
          >
            {[true, false].map(bool =>
              <MenuItem key={bool.toString()} value={bool.toString()}>
                {optionsTranslation('boolean', bool.toString())}
              </MenuItem>
            )}
          </Select>
          )
        }

        return optionsTranslation('boolean', info.renderValue()?.toString())
      }
    }),
    columnHelper.accessor('isApprovalNeeded', {
      id: 'isApprovalNeeded',
      header: () => t('shipment.table.approvalNeeded'),
      cell: info => {
        const declined = info.row.original.approvalStatus === SampleShipmentApproval.DECLINED
        const createSubOrderShipment = info.row.original.isCreatedShipment
        const disabled = declined || createSubOrderShipment

        if(isEdit && !disabled){
          return(
            <Select
            size='small'
            value={info.renderValue()}
            onChange={(e) => {
              resetRefInput()
              onSetDataValue(info.row.index, 'isApprovalNeeded', str2bool(e.target.value))
            }}
          >
            {[true, false].map(bool =>
              <MenuItem key={bool.toString()} value={bool.toString()}>
                {optionsTranslation('boolean', bool.toString())}
              </MenuItem>
            )}
          </Select>
          )
        }

        return (

          <Box textAlign="center">
            {optionsTranslation('boolean', info.renderValue()?.toString())}
          </Box>

        )
      }
    }),
    columnHelper.accessor('sampleWeight', {
      id: 'sampleWeight',
      header: () => t('shipment.table.weight'),
      cell: info => {
        const declined = info.row.original.approvalStatus === SampleShipmentApproval.DECLINED
        const createSubOrderShipment = info.row.original.isCreatedShipment
        const disabled = declined || createSubOrderShipment

        return(<>{renderEditSampleWeight(info, disabled)}</>)
      }
    }),
    columnHelper.accessor('label', {
      id: 'label',
      header: () => t('shipment.table.action'),
      cell: info => {
        const declined = info.row.original.approvalStatus === SampleShipmentApproval.DECLINED
        const createSubOrderShipment = info.row.original.isCreatedShipment
        const disabled = isEdit || fulfilled || declined || createSubOrderShipment

        return (
          <Stack direction="row" spacing={1} textAlign="center">
            <Button
              variant="contained"
              size="small"
              sx={{p: 0.5, minWidth: 'fit-content' , backgroundColor: isEmpty(samplesToBeShipment[info.row.id].label) ? colors.secondary.main : colors.primary.main}}
              disabled={disabled}
              onClick={() => {
                setIndexSamples(parseInt(info.row.id))
                setOpenGenerateLabel(true)
              }}
            >
              <Feed />
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{p: 0.5, minWidth: 'fit-content'}}
              disabled={disabled}
              onClick={() => {
                setIndexSamples(parseInt(info.row.id))
                setOpenSampleModal(true)
              }}
            >
              <Edit />
            </Button>
            <Button
              variant={declined ? "text" : "contained"}
              color="warning"
              size="small"
              sx={{minWidth: 'fit-content', ...declined && {color: `#F54C55 !important`}}}
              disabled={disabled}
              onClick={() => {
                setIsMultipleDecline(false)
                setTransactionItemId(info.row.original.uniqueToken)
                setDeclineModal(true)
              }}
            >
              {declined ? t('shipment.button.declined') : t('shipment.button.decline')}
            </Button>
          </Stack>
        )
      }
    }),
    columnHelper.accessor('expand', {
      id: 'expand',
      header: () => '',
      cell: info =>
        <Box textAlign="center">
          <IconButton
            size="small"
            color="primary"
            onClick={info.row.getToggleExpandedHandler()}
          >
            {info.row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection, expanded },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    manualExpanding: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  const onSaveSampleShipment = async () => {
    setOpenConfirmShipment(false)

    globalAction(notificationStore, {
      action: saveSampleShipment,
      afterAction: () => setOpenSuccessCreatedShipment(true)
    })
  }

  const onSaveSampleOrder = async () => {
    try {
      notificationStore.setLoading(true)
      await updateSampleOrder(params.id)

      setOpenSuccessCreatedShipment(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const onSaveSubOrderSampleShipment = async (fulfillmentType, sampleLocation) => {
    try {
      notificationStore.setLoading(true)
      await updateSampleOrder(params.id)
      await saveSubOrderSampleShipment(fulfillmentType, sampleLocation)

      onGetSampleShipment()
    } finally {
      notificationStore.setLoading(false)
      setOpenSuccessCreatedShipmentSubOrder(true)
    }
  }


  const onGetSampleShipment = () => {
    globalAction(notificationStore, {
      action: async () => {
        await getSampleOrder(params.id)
        setChanged(true)
      },
    })
  }

  const handlerMultipleDecline = () => {
    const dataSamples = samplesToBeShipment.filter((item,index) => uniq(Object.keys(rowSelection).map(key => parseInt(key))).includes(index))

    if(dataSamples.length === 0) return

    //reset data
    dataMultipleDecline.length = 0

    dataSamples.map( data => {
      setDataMultipleDecline((prevGroups) => [
        ...prevGroups,
        data.uniqueToken
      ]);
    })

  }

  useEffect(() => {
    if (changed) {
      setData(cloneDeep(samplesToBeShipment))
      setChanged(false)
      if(isMultipleDecline){
        setIsMultipleDecline(false)
        onGetSampleShipment()
      }
    }
  }, [changed])

  useEffect(() => {
    onGetSampleShipment()
  }, [])

  return (
    <Box px={4} py={2.5}>
      <HeaderTitleWide
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.sampleOrders')})}
        title={t(`order.orderNumber`, { number: shipmentInformation?.sampleOrderNumber })}
        rightContent={renderButtons()}
      />

      <Box>
        <Typography variant='h5'>{t('shipment.generalInformationForm')}</Typography>
        <Typography variant='body2' color="primary" mb={2}>
          {t('shipment.type')}: {optionsTranslation('fulfillmentType', shipmentInformation?.sampleFulfillmentType)}
        </Typography>

        <Box className={classes.wrapper}>
          {shipmentInformation && <ShipmentInformation shipmentInformation={shipmentInformation} />}
        </Box>
      </Box>
      <Box sx={{my: 2}}>
        <Typography variant='h5' mb={2}>{t('shipment.sampleInformation')}</Typography>

        <Box className={classes.wrapper}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant='h6'>
              {t('shipment.table.sample', { count: samplesToBeShipment?.length })}
            </Typography>
              <Stack direction="row" spacing={2}>
                {!fulfilled && (
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<Feed />}
                      onClick={() => setMultipleOpenGenerateLabel(true)}
                      disabled={isEdit || uniq(Object.keys(rowSelection).map(key => parseInt(key))).length === 0}
                    >
                      {t('shipment.table.generateLabel')}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<CloseOutlined />}
                      onClick={() => {
                        setIsMultipleDecline(true)
                        handlerMultipleDecline()
                        setDeclineModal(true)
                      }}
                      disabled={isEdit || uniq(Object.keys(rowSelection).map(key => parseInt(key))).length < 2}
                    >
                      {t("shipment.button.decline")}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<Edit />}
                      onClick={() => setIsEdit(true)}
                      disabled={isEdit}
                    >
                      {t("shipment.button.edit")}
                    </Button>
                </>
                )}
              </Stack>

          </Stack>

          {!changed &&
	          <BasicTable
		          table={table}
		          renderSubComponent={renderSubComponent}
              containerProps={{sx: {overflowX: 'auto'}}}
	          />
					}

          {isEdit && (
            <Stack direction="row" spacing={2} justifyContent="end" mt={3}>
              <Button
                variant="contained"
                sx={{width: 200}}
                onClick={() => {
                  setSamplesToBeShipment(data)
                  setIsEdit(false)
                  setOpenSuccessEditedSample(true)
                }}
              >
                {t(`shipment.button.saveChanges`)}
              </Button>
              <Button
                variant="outlined"
                sx={{width: 200}}
                onClick={() => {
                  setData(cloneDeep(samplesToBeShipment))
                  setIsEdit(false)
                }}
              >
                {t("common.cancel")}
              </Button>
            </Stack>
          )}
        </Box>
      </Box>

      <Box sx={{mt: 2, mb: 4}}>
        {/*<Typography variant='h5' mb={2}>{t(`shipment.${unfulfilled ? 'shipmentsToBeCreated' : 'shipmentsHadBeenCreated'}`)}</Typography>*/}
        {shipmentInformation.fulfillmentStatus !== constantsFulfillmentStatus[3] && <Typography variant='h5' mb={2}>{t(`shipment.subOrder`)}</Typography>}

        {Object.keys(groupSamplesToBeShipment).map(group => {
          if(groupSamplesToBeShipment[group].filter( x => x.approvalStatus !== "declined").length === 0) return

          return (
            <ShipmentTable
              key={group}
              groupSamplesToBeShipment={groupSamplesToBeShipment}
              group={group}
              onSubmitEmail={setThirdPartyEmails}
              onUpdateStatus={setSubOrderStatus}
              onUpdateNote={setSubOrderNote}
              onCreateShipment={(fulfillmentType, sampleLocation) => {
                setSubOrderIsCreatedShipment(fulfillmentType, sampleLocation)
                onSaveSubOrderSampleShipment(fulfillmentType, sampleLocation)
              }}
              isFormComplete={isFormComplete}
              isFormCompleteWithoutEmail={isFormCompleteWithoutEmail}
            />
          )
        })}
        <Stack direction="row" spacing={2} justifyContent="end" mt={3}>
          <LightTooltip title={!isFormComplete ? t('shipment.button.createShipmentTooltip') : ''} arrow>
            <span>
              <Button
                 variant="contained"
                 sx={{width: 200}}
                 onClick={onSaveSampleOrder}
              >
                {t(`shipment.button.saveChanges`)}
              </Button>
            </span>
          </LightTooltip>
          <Button
            variant="outlined"
            sx={{width: 200}}
            onClick={() => navigate('/sample-orders')}
          >
            {t("common.cancel")}
          </Button>
        </Stack>
      </Box>

      <ShipmentInfoModal
        id={params.id}
        shipment={shipmentInformation}
        open={openShipmentInfoModal}
        onClose={() => setOpenShipmentInfoModal(false)}
        setShipment={(shipmentInfo) => setSampleOrderValue('shipmentInformation', shipmentInfo)}
        onSubmit={() => {
          setOpenShipmentInfoModal(false)
          setDescriptionSuccess(t('shipment.success.successfullyUpdatedShipment'))
          setOpenSuccess(true)
        }}
      />

      <ModalMessage
        open={openSuccess}
        onSubmit={() => {
          setOpenSuccess(false)
        }}
        description={descriptionSuccess}
        buttonText={t('common.backToPage')}
      />

      <ModalMessage
        open={openSuccessEditedSample}
        description={t('shipment.success.successfullyEditedSamples')}
        onSubmit={() => setOpenSuccessEditedSample(false)}
      />

      <ModalMessage
        open={openSuccessCreatedShipment || openSuccessCreatedShipmentSubOrder}
        description={openSuccessCreatedShipment? t('shipment.success.successfullySave') : t('shipment.success.successfullyCreatedShipment')}
        onSubmit={() => {
          if(openSuccessCreatedShipmentSubOrder){
            setOpenSuccessCreatedShipmentSubOrder(false)
          } else {
            setOpenSuccessCreatedShipment(false)
            navigate('/sample-orders')
          }
        }}
        buttonText={t('common.continue')}
      />

      <ModalConfirm
        open={openConfirmShipment}
        onClose={() => setOpenConfirmShipment(false)}
        onConfirm={onSaveSampleShipment}
        confirm={t('shipment.button.confirm')}
        title={t('shipment.createSampleShipment')}
        description={`
          ${t('shipment.modal.localShipment', {count: sampleShipmentCounter.local})}
          ${t('shipment.modal.thirdPartyShipment', {count: sampleShipmentCounter.thirdParty})}
          <br /><br />
          ${t('shipment.modal.createShipmentConfirmation')}
        `}
      />

      {indexSamples >= 0 && (
        <>
          <GenerateLabel
            index={indexSamples}
            samplesToBeShipment={samplesToBeShipment}
            open={openGenerateLabel}
            onClose={() => setOpenGenerateLabel(false)}
            onSubmit={(index, data) => {
              setSamplesToBeShipmentGenerateLabel(index, data)
            }}
          />

          <SampleInformationModal
            open={openSampleModal}
            onClose={() => setOpenSampleModal(false)}
            sample={samplesToBeShipment[indexSamples].sample}
            setSample={(sample) => {
              setSamplesToBeShipmentSample(indexSamples, sample)
              const newData = data.map((item, index) => {
                if (index === indexSamples) {
                  return {
                    ...item,
                    sampleName: sample.name,
                    sampleGrade: sample.grade,
	                  sampleLocation: sample.sampleLocation,
                    sample: sample
                  }
                }

                return item
              })
              setData(newData)
              setSamplesToBeShipment(newData)
              setIndexSamples(-1)
            }}
          />
        </>
      )}

      <DeclineModal
        isOpen={declineModal}
        isMultipleDecline={isMultipleDecline}
        onClose={() => setDeclineModal(false)}
        id={params.id}
        transactionItemId={transactionItemId}
        uniqueTokenData = {dataMultipleDecline}
        onSuccess={() => {
          setChanged(true)
          setDeclineModal(false)
        }}
      />
      {!isEmpty(samplesToBeShipment) &&
          <MultipleGenerateLabel
              index={0}
              samplesToBePrintLabel={
                uniq(Object.keys(rowSelection).map(key => parseInt(key))).length === 0 ? samplesToBeShipment :
                  samplesToBeShipment.filter((item,index) => uniq(Object.keys(rowSelection).map(key => parseInt(key))).includes(index))
              }
              open={openMultipleGenerateLabel}
              onClose={() => setMultipleOpenGenerateLabel(false)}
              onSubmit={(data) => {
                data.map( d => {
                  setSamplesToBeShipmentMultiGenerateLabel(d)
                })
              }}
          />
      }
    </Box>
  )
})

export default SampleOrderForm
